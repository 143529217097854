<template>
  <div class="px-10 pt-12">
    <div class="text-center">
      <div class="rounded-full  shadow-2xl bg-white flex justify-center items-center w-20 h-20 mx-auto mb-5">
        <BigLock class="h-8 w-8"/>
      </div>
      <h2 class="mb-10">Go Pro</h2>
      <div class="h-56 flex flex-col justify-center items-center bg-darkSmoke rounded">
        <span class="font-bold text-4xl">$10</span>
        <span class="font-bold">PER MONTH</span>
      </div>
      <div class="absolute px-10 w-full left-0 right-0 bottom-0">
        <router-link
          :to="{name:'Home'}"
          class="btn-primary w-full text-white bg-lightPurple text-base block text-center mb-3"
        >
          Subscribe
        </router-link>

        <router-link :to="{ name: 'home' }">
          Skip and use for free...
        </router-link>
      </div>
    </div>
  </div>
</template>
I
<script>
import BigLock from "@/components/svg/BigLock";

export default {
  name: "ProPlan",
  components: {BigLock}
}
</script>

<style scoped>

</style>
