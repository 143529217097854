<template>
  <svg width="44" height="49" viewBox="0 0 44 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M15.7153 7.06682C17.3445 5.43765 19.5541 4.52239 21.8581 4.52239C24.1621 4.52239 26.3717 5.43765 28.0009 7.06682C29.63 8.69599 30.5453 10.9056 30.5453 13.2096V19.715H6.58527C2.94819 19.715 -0.000244141 22.6634 -0.000244141 26.3005V41.5733C-0.000244141 45.2103 2.94819 48.1588 6.58527 48.1588H37.1307C40.7678 48.1588 43.7163 45.2103 43.7163 41.5733V26.3005C43.7163 22.6634 40.7678 19.715 37.1307 19.715H34.9891V13.2096C34.9891 9.72707 33.6056 6.38715 31.1431 3.92462C28.6805 1.46208 25.3406 0.0786438 21.8581 0.0786438C18.3755 0.0786438 15.0356 1.46208 12.5731 3.92462C10.1105 6.38715 8.72711 9.72707 8.72711 13.2096C8.72711 14.4367 9.72187 15.4315 10.949 15.4315C12.1761 15.4315 13.1709 14.4367 13.1709 13.2096C13.1709 10.9056 14.0861 8.69599 15.7153 7.06682ZM32.7594 24.1588C32.762 24.1588 32.7646 24.1588 32.7672 24.1588C32.7698 24.1588 32.7724 24.1588 32.775 24.1588H37.1307C38.3136 24.1588 39.2725 25.1177 39.2725 26.3005V41.5733C39.2725 42.7561 38.3136 43.715 37.1307 43.715H6.58527C5.40241 43.715 4.44351 42.7561 4.44351 41.5733V26.3005C4.44351 25.1177 5.40241 24.1588 6.58527 24.1588H32.7594Z"
          fill="url(#paint0_linear)"/>
    <defs>
      <linearGradient id="paint0_linear" x1="-6.16539" y1="9.01662" x2="46.813" y2="43.3852"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#FD8619"/>
        <stop offset="0.354167" stop-color="#FD5C19"/>
        <stop offset="1" stop-color="#EF1010"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "BigLock"
}
</script>

<style scoped>

</style>
